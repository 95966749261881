import React from 'react';
import pt from 'prop-types';
import Text from '../../../../ui/text';
import Link from '../../../../ui/link';
import Image from '../../../../ui/image';
import Icon from '../../../../ui/icon';
import { combine } from '../../../../../helpers/styles';
import * as styles from './styles.module.scss';

const Participant = ({
  className,
  imgClassName,
  logo,
  name,
  additionalInfo,
  location,
  additionalInfo2,
  link,
  htmlId,
}) => {
  const renderItem = () => (
    <div
      className={combine(
        styles.participantWrapper,
        !logo?.url && styles.withoutImage
      )}
    >
      {logo?.url && (
        <div className={combine(styles.imgWrapper, imgClassName)}>
          <Image image={logo} disableGatsbyImage />
        </div>
      )}
      <div>
        <Text as="h3" type="h3" variant="semibold" id={htmlId}>
          {name}
        </Text>
        {Boolean(additionalInfo) && (
          <Text
            as="span"
            type="h3"
            variant="semibold"
            className={styles.additionalInfo}
          >
            {additionalInfo}
          </Text>
        )}
        <Text as="span" type="p1">
          {location}
        </Text>
        {Boolean(additionalInfo2) && (
          <Text as="span" type="p1" className={styles.additionalInfo2}>
            {additionalInfo2}
          </Text>
        )}
      </div>
      {link.linkToPage && (
        <div className={styles.iconWrapper}>
          <Icon name="arrow-right" size="3x" />
        </div>
      )}
    </div>
  );

  if (!link.linkToPage) {
    return renderItem();
  }

  return (
    <Link link={link} className={className} useGatsbyLink={false}>
      {renderItem()}
    </Link>
  );
};

Participant.propTypes = {
  name: pt.string.isRequired,
  location: pt.string.isRequired,
  additionalInfo: pt.string,
  link: pt.object,
  logo: pt.object,
  className: pt.string,
  imgClassName: pt.string,
  htmlId: pt.string,
};

Participant.defaultProps = {
  logo: null,
  className: '',
  imgClassName: '',
  link: null,
  htmlId: '',
};

export default Participant;
