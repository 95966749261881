import React, { useContext } from 'react';
import Headline from '../../components/ui/headline';
import Image from '../../components/ui/image';
import Text from '../../components/ui/text';
import * as styles from './styles.module.scss';
import Container from '../../modules/container';
import Layout from '../../modules/layout';
import Participant from '../../components/contributions/detail/components/participant';
import { getCopy } from '../../helpers/copy';
import { MicrocopyContext } from '../../context/microcopy.context';

const PortfolioDetailTemplate = ({ contributionData }) => {
  const microcopy = useContext(MicrocopyContext);

  const {
    image1FileUrl: image,
    name,
    description,
    logo,
    companyName,
    companyProfileId,
    location,
    slug,
  } = contributionData;

  return (
    <Layout title={name} description={description} slug={slug}>
      <Container className={styles.outerContainer} fullWidth>
        <Container className={styles.container}>
          {image && <Image image={{ url: image }} />}
          <div>
            <Headline type="h1" level="h1" variant="semibold" text={name} />
            {description && <Text>{description}</Text>}
            <Text
              as="span"
              type="infotext"
              variant="semibold"
              className={styles.designerHl}
            >
              {getCopy('portfolio.designer', microcopy)}
            </Text>
            <Participant
              logo={{
                url: logo,
                description: companyName,
              }}
              name={companyName}
              location={`${location}`}
              link={{
                linkToPage: slug.split('/portfolio').shift(),
              }}
            />
          </div>
        </Container>
      </Container>
    </Layout>
  );
};

export default PortfolioDetailTemplate;
